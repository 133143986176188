import Timer from "@/business/common/Timer";

export default class Experiment {

    private _timer: Timer = new Timer();
    private _beaker: Beaker = new Beaker();
    private _light: Light = new Light();

    private _lastBubble: Date = new Date();
    private _history: boolean[] = [];

    get timer(): Timer {
        return this._timer;
    }

    get beaker(): Beaker {
        return this._beaker;
    }

    get light(): Light {
        return this._light;
    }

    public secondsSinceLastBubble(): number {
        return (new Date().getTime() - this._lastBubble.getTime()) / 1000
    }

    public rate(): number {
        const data = {
            "low": {
                10: [0, 8, 16, 20, 22, 24, 24, 24, 24, 24, 24],
                25: [0, 32, 48, 56, 64, 72, 74, 76, 76, 76, 76],
                40: [0, 24, 40, 48, 52, 56, 58, 60, 64, 64, 64]
            },
            "high": {
                10: [0, 12, 20, 24, 32, 36, 38, 42, 46, 48, 48],
                25: [0, 32, 60, 80, 96, 108, 116, 120, 128, 132, 136],
                40: [0, 28, 52, 68, 80, 88, 96, 100, 104, 108, 112]
            }
        };

        const co2Level = this.beaker.co2Level;
        const temperature = this.beaker.temperature;
        const intensity = Math.round(this.light.intensity / 5);

        // @ts-ignore
        let baseRate = data[co2Level][temperature][intensity]; // Seconds Between Bubbles
        switch (this.light.color) {
            case "green":
                baseRate = Math.round(baseRate * 0.3);
                break;
            case "blue":
                baseRate = Math.round(baseRate * 0.9);
                break;
            case "white":
                baseRate = Math.round(baseRate);
                break;
            case "red":
                baseRate = Math.round(baseRate * 0.8);
                break;
        }
        return 120 / baseRate;
    }

    public generateBubble(): boolean {
        let generateBubble = false;
        if (this.secondsSinceLastBubble() > this.rate()) {
            this._lastBubble = new Date();
            generateBubble = true;
        }
        return generateBubble;
    }
}


export class Beaker {

    private _temperature: 10 | 25 | 40 = 10;
    private _co2Level: "high" | "low" = "low";

    get temperature(): 10 | 25 | 40 {
        return this._temperature;
    }

    set temperature(value: 10 | 25 | 40) {
        this._temperature = value;
    }

    public toggleCO2Level() {
        this._co2Level = this._co2Level === "high" ? "low" : "high";
    }

    get co2Level(): "high" | "low" {
        return this._co2Level;
    }

    set co2Level(value: "high" | "low") {
        this._co2Level = value;
    }

}

export class Light {

    private _intensity: number = 0;
    private _color: "green" | "blue" | "white" | "red" = "white";

    get intensity(): number {
        return this._intensity;
    }

    set intensity(value: number) {
        if (value > 50) {
            value = 50;
        }
        value = Math.round(value);
        this._intensity = value;
    }

    get color(): "green" | "blue" | "white" | "red" {
        return this._color;
    }

    set color(value: "green" | "blue" | "white" | "red") {
        this._color = value;
    }

}