<template>
  <nav-bar subtitle="Photo Lab">
    <div class="canvas-wrapper" :style="{zoom: calcZoom()}">
      <div class="canvas">
        <div class="notice-text" v-if="noticeText">{{ noticeText }}</div>

        <div class="beaker">
          <div class="thermometer-bg"></div>
          <div class="thermometer-fg" v-bind:style="{ 'height': tempHeight()}"></div>
          <div @mouseover="showText('Click to set temperature to 10')" @mouseleave="hideText()"
               @click="experiment.beaker.temperature = 10" class="temp-to-1"></div>
          <div @mouseover="showText('Click to set temperature to 25')" @mouseleave="hideText()"
               @click="experiment.beaker.temperature = 25" class="temp-to-2"></div>
          <div @mouseover="showText('Click to set temperature to 40')" @mouseleave="hideText()"
               @click="experiment.beaker.temperature = 40" class="temp-to-3"></div>
          <img class="image" src="@/assets/photoLab/Beaker.svg"/>
          <div class="bubble"></div>
          <div class="bubble"></div>
          <div class="bubble"></div>
          <div class="bubble"></div>
        </div>

        <div class="lamp">
          <img class="image" src="@/assets/photoLab/Lamp.svg"/>
          <div class="light"
               :style="{ 'background-color': calcColor(experiment.light.color, experiment.light.intensity)}"></div>
        </div>

        <div class="light-controls">
          <div class="display">{{ experiment.light.intensity }}</div>
          <div class="intensity-wrapper">
            <div :style="{ 'background-color': calcColor(experiment.light.color, level)}"
                 @click="experiment.light.intensity = level"
                 @mouseleave="hideText()"
                 @mouseover="showText(`Set light intensity to ${level}`)"
                 class="intensity" v-for="level in availableLightLevels()">
              <span v-if="experiment.light.intensity===level">💡</span>
            </div>
            <div class="is-clearfix"></div>
          </div>

          <button @click="experiment.light.color = 'white'"
                  @mouseover="showText('Click to remove lamp filter')" @mouseleave="hideText()"
                  class="button white" :disabled="experiment.light.color === 'white'"/>
          <button @click="experiment.light.color = 'red'"
                  @mouseover="showText('Click to set lamp filter to red')" @mouseleave="hideText()"
                  class="button red" :disabled="experiment.light.color === 'red'"/>
          <button @click="experiment.light.color = 'green'"
                  @mouseover="showText('Click to set lamp filter to green')" @mouseleave="hideText()"
                  class="button green" :disabled="experiment.light.color === 'green'"/>
          <button @click="experiment.light.color = 'blue'"
                  @mouseover="showText('Click to set lamp filter to blue')" @mouseleave="hideText()"
                  class="button blue" :disabled="experiment.light.color === 'blue'"/>
        </div>

        <div class="timer">
          <img class="image" src="@/assets/photoLab/Timer.svg"/>
          <div class="text">{{ experiment.timer.getMinutes() }}</div>
          <div @mouseover="showText('Click to start timer')" @mouseleave="hideText()"
               @click="experiment.timer.startTimer()" class="start"></div>
          <div @mouseover="showText('Click to stop timer')" @mouseleave="hideText()"
               @click="experiment.timer.stopTimer()" class="stop"></div>
        </div>

        <div class="co2" @click="experiment.beaker.toggleCO2Level()"
             @mouseover="showText('Click to toggle CO2 level')" @mouseleave="hideText()">
          <div class="display">{{ experiment.beaker.co2Level === 'low' ? '' : 'CO₂ added' }}</div>
          <img v-if="experiment.beaker.co2Level === 'low'" class="full"
               src="@/assets/photoLab/CO2 Full.svg"/>
          <img v-else class="half" src="@/assets/photoLab/CO2 Half.svg"/>
        </div>
      </div>
    </div>
    <div class="attribution">
      Based on <a href="http://www.kscience.co.uk/animations/photolab.htm" target="_blank">http://www.kscience.co.uk/animations/photolab.htm</a>
      by KScience
    </div>
  </nav-bar>
</template>

<script>
import Experiment from "@/business/PhotoLab";
import NavBar from "@/components/NavBar";

export default {
  name: "PhotoLab",
  components: {NavBar},
  data() {
    return {
      experiment: new Experiment(),
      noticeText: null,
      running: true
    }
  },
  methods: {
    calcZoom() {
      const height = document.documentElement.clientHeight - 100;
      return height > 900 ? 1 : height / 900;
    },
    showText(text) {
      this.noticeText = text;
    },
    hideText() {
      this.noticeText = "";
    },
    tempHeight() {
      switch (this.experiment.beaker.temperature) {
        case 10:
          return "30px";
        case 25:
          return "165px";
        case 40:
          return "310px";
      }
    },
    calcColor(color, intensity) {
      const amount = ((intensity / 50) * 0.8 + 0.2) * 255;
      switch (color) {
        case "blue":
          return `rgb(0,0,${amount})`;
        case "red":
          return `rgb(${amount},0,0)`;
        case "green":
          return `rgb(0,${amount},0)`;
        case "white":
          return `rgb(${amount},${amount},${amount})`;
      }
    },
    availableLightLevels() {
      const levels = [];
      for (let i = 0; i <= 50; i = i + 5) {
        levels.push(i);
      }
      return levels;
    },
    refresh() {
      this.$forceUpdate();
      if (this.experiment.generateBubble()) {
        this.triggerBubble();
      }
      if (this.running) {
        setTimeout(this.refresh, 100);
      }
    },
    triggerBubble() {
      let done = false;
      let bubbles = document.getElementsByClassName("bubble")
      for (const bubble of bubbles) {
        if (!done && bubble.style.animationPlayState !== "running") {
          done = true;
          bubble.style.display = "block";
          bubble.style.animationPlayState = "running";
          setTimeout(() => {
            bubble.style.display = "none";
            bubble.style.animationPlayState = "paused";
          }, 1000);
        }
      }
    }
  },
  mounted() {
    this.refresh();
  },
  beforeDestroy() {
    this.running = false;
  }
}
</script>

<style lang="scss" scoped>
$width: 1000px;
$height: 750px;

$co2-width: 119.387px;
$co2-height: 167.773px;

$beaker-width: 332.341px;
$beaker-height: 492.019px;

$timer-width: 203.3px;
$timer-height: 137.112px;

$lamp-width: 499.869px;
$lamp-height: 507.694px;

.attribution {
  font-size: 14px;
  padding: 10px;
  color: #666666;

  a {
    font-weight: normal;
    color: #666666;
  }
}

.canvas-wrapper {
  flex-grow: 1;

  .canvas {
    margin-left: auto;
    margin-right: auto;
    width: $width;
    height: $height;
    position: relative;
    z-index: 0;

    .notice-text {
      padding: 10px;
      font-size: 16px;
      border: 3px solid black;
      position: absolute;
      top: 10px;
      left: 10px;
    }

    .light-controls {
      position: absolute;
      top: 70px;
      left: 50px;

      .display {
        text-align: center;
        font-size: 20px;
      }

      .intensity-wrapper {
        transition: all 0.5s;
        border: 3px solid black;
        border-radius: 5px;
        margin-top: 10px;
        margin-bottom: 10px;
        background-color: black;
        width: 391px;

        .intensity {
          width: 35px;
          height: 40px;
          float: left;
          cursor: pointer;
          text-align: center;
          font-size: 26px;
          color: black;
        }
      }

      .white, .red, .blue, .green {
        border: 3px solid black;
        border-radius: 10px;
        width: 40px;
        height: 40px;
        float: left;
        margin-right: 10px;
        cursor: pointer;
      }

      .white {
        background-color: white;
      }

      .red {
        background-color: red;
      }

      .blue {
        background-color: blue;
      }

      .green {
        background-color: green;
      }
    }

    .lamp {
      width: $lamp-width;
      height: $lamp-height;
      position: absolute;
      top: 25px;
      right: 25px;

      .image {
        z-index: 2;
      }

      .light {
        z-index: 1;
        position: absolute;
        transition: all 0.5s;
        width: 150px;
        height: 215px;
        top: 10px;
        left: 10px;
      }
    }

    .beaker {
      position: absolute;
      bottom: 25px;
      left: 50px;
      height: $beaker-height;
      width: $beaker-width;

      .image {
        z-index: 6;
      }

      .thermometer-bg, .thermometer-fg, .temp-to-1, .temp-to-2, .temp-to-3 {
        position: absolute;
        left: 45px;
        bottom: 150px;
        width: 25px;
      }

      .temp-to-1, .temp-to-2, .temp-to-3 {
        z-index: 11;
        cursor: pointer;
        height: 20px;
      }

      .temp-to-1 {
        bottom: 170px;
      }

      .temp-to-2 {
        bottom: 305px;
      }

      .temp-to-3 {
        bottom: 450px;
      }

      .thermometer-bg {
        z-index: 5;
        background-color: white;
        height: 325px;
      }

      .thermometer-fg {
        z-index: 5;
        background-color: red;
        transition: all 0.5s;
      }

      .bubble {
        position: absolute;
        width: 15px;
        height: 15px;
        left: 270px;
        bottom: 50px;
        border-radius: 7.5px;
        background: rgba(255, 255, 255, .5);
        animation-name: moveUp;
        animation-duration: 1s;
        z-index: 10;
        display: none;
        border: 2px solid black;
      }

      @keyframes moveUp {
        from {
          bottom: 50px;
        }
        to {
          bottom: 230px;
        }
      }
    }

    .co2 {
      position: absolute;
      bottom: 50px;
      right: 100px + $timer-width + 100px;
      height: $co2-height;
      width: $co2-width;
      cursor: pointer;

      .display {
        text-align: center;
        font-size: 20px;
      }

      .full, .half {
        top: 30px;
        left: 0;
        z-index: 2;
        position: absolute;
      }
    }

    .timer {
      z-index: 1;
      position: absolute;
      bottom: 25px;
      right: 100px;
      height: $timer-height;
      width: $timer-width;

      .start, .stop {
        z-index: 3;
        position: absolute;
        width: $timer-width * 0.18;
        height: $timer-height * 0.12;
        top: $timer-height * 0.08;
        left: $timer-width * 0.23;
        cursor: pointer;
      }

      .stop {
        left: $timer-width * 0.60;
      }

      .image {
        z-index: 2;
        position: absolute;
      }

      .text {
        z-index: 3;
        position: absolute;
        width: 100%;
        top: $timer-height * 0.38;
        vertical-align: middle;
        font-size: 30px;
        text-align: center;
        color: black;
      }
    }
  }
}
</style>
