<template>
  <nav-bar>
    <div class="container is-fluid">
      <div class="columns">
        <div class="row column is-4">
          <div class="subtitle">Biology</div>
          <ul>
            <li>
              <router-link to="/biology/photolab">Photo Lab</router-link>
            </li>
            <li v-if="showHidden">
              <router-link to="/biology/photolabswf">Photo Lab - Flash</router-link>
            </li>
            <li>
              <router-link to="/biology/ecosystem">Ecosystem Lab</router-link>
            </li>
            <li v-if="showHidden">
              <router-link to="/biology/kidney">Kidney - Flash</router-link>
            </li>
            <li v-if="showHidden">
              <router-link to="/biology/howosmosisworks">Osmosis - Flash</router-link>
            </li>
            <li v-if="showHidden">
              <router-link to="/biology/atp">ATP and Energy Storage - Flash</router-link>
            </li>
            <li v-if="showHidden">
              <router-link to="/biology/earthworm">Earthworm Dissection - Flash</router-link>
            </li>
          </ul>
        </div>
        <div class="row column is-4">
          <div class="subtitle">Genetics</div>
          <ul>
            <li v-if="showHidden">
              <router-link to="/genetics/clickclone">Click Clone - Flash</router-link>
            </li>
            <li v-if="showHidden">
              <router-link to="/genetics/spacedoctor">Space Doctor - Flash</router-link>
            </li>
          </ul>
        </div>
        <div class="row column is-4">
          <div class="subtitle">Science</div>
          <ul>
            <li v-if="showHidden">
              <router-link to="/science/scaleofuniverse">Scale of Universe - Flash</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav-bar>
</template>

<script>
import NavBar from "@/components/NavBar";

export default {
  name: "Home",
  components: {NavBar},
  computed: {
    showHidden() {
      return this.$route.name === 'home-w-hidden';
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding: 0;
  height: 100%;

  a, a:hover {
    color: #666666;
  }

  .subtitle {
    color: green;
    border-bottom: 2px solid #CCCCCC;
    text-align: center;
    padding: 10px;
    font-weight: bold;
  }
}
</style>