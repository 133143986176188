<template>
  <nav-bar subtitle="Photo Lab">
    <div class="wrapper">
      <div ref="container"></div>
    </div>
  </nav-bar>
</template>

<script>
import NavBar from "@/components/NavBar";


export default {
  name: "PhotoLabSwf",
  components: {NavBar},
  mounted() {
    this.loadSwift();
  },
  methods: {
    async loadSwift() {
      const player = await this.$parent.getPlayer();
      this.$refs.container.appendChild(player);
      player.load("/flash/photolab.swf");
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  flex-grow: 1;
}
</style>
