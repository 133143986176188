<template>
  <div class="parent container">
    <header>
      <span class="main"><router-link to="/">Virtual Science Labs</router-link></span> <span
        v-if="subtitle">- <span
        class="sub">{{ subtitle }}</span></span>
    </header>
    <section class="content container">
      <slot></slot>
    </section>
    <footer>Driftless Science</footer>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  props: {
    subtitle: {type: String}
  }
}
</script>

<style lang="scss" scoped>
footer {
  display: none;
}

.parent {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  height: 100%;

  .content {
    width: 100%;
    flex-grow: 1;
    padding-top: 18px;
    margin-bottom: 18px;

    display: flex;
    flex-flow: column;
    align-items: stretch;
  }

  header {
    padding: 10px;
    font-size: 18px;
    background-color: white;
    color: #666666;
    border-bottom: 2px solid #CCCCCC;

    a {
      color: #666666;
    }

    .main {
      font-weight: bold;
    }

    .sub {
      font-weight: normal;
    }
  }

  footer {
    border-top: 1px solid #CCCCCC;
    color: #999999;
    font-size: 14px;
    padding: 10px;
  }
}
</style>
