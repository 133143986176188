import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import PhotoLab from '../views/PhotoLab.vue'
import PhotoLabSwf from '../views/PhotoLabSwf.vue'
import EcosystemLab from '../views/EcosystemLab.vue'
import ClickAndClone from '../views/ClickAndClone.vue'
import SpaceDoctor from '../views/SpaceDoctor.vue'
import Kidney from '../views/Kidney.vue'
import ScaleOfUniverse from '../views/ScaleOfUniverse.vue'
import HowOsmosisWorks from '../views/HowOsmosisWorks.vue'
import ATP from '../views/ATP.vue'
import EarthWorm from '../views/EarthWormSwf.vue'

Vue.use(VueRouter);

const routes = [
    {path: '/', name: 'home', meta: {title: "Driftless Science"}, component: Home},
    {path: '/show-hidden', name: 'home-w-hidden', meta: {title: "Driftless Science"}, component: Home},
    {path: '/biology/photolab', name: 'photolab', meta: {title: "Photo Lab"}, component: PhotoLab},
    {path: '/biology/photolabswf', name: 'photolabswf', meta: {title: "Photo Lab"}, component: PhotoLabSwf},
    {path: '/biology/ecosystem', name: 'ecosystem', meta: {title: "Ecosystem Lab"}, component: EcosystemLab},
    {path: '/biology/kidney', name: 'kidney', meta: {title: "Kidney"}, component: Kidney},
    {path: '/biology/howosmosisworks', name: 'howosmosisworks', meta: {title: "How Osmosis Works"}, component: HowOsmosisWorks},
    {path: '/biology/atp', name: 'atp', meta: {title: "ATP and Energy Storage"}, component: ATP},
    {path: '/biology/earthworm', name: 'earthworm', meta: {title: "Earthworm Dissection"}, component: EarthWorm},
    {path: '/science/scaleofuniverse', name: 'scaleofuniverse', meta: {title: "Scale of Universe"}, component: ScaleOfUniverse},
    {path: '/genetics/clickclone', name: 'clickclone', meta: {title: "Click and Clone"}, component: ClickAndClone},
    {path: '/genetics/spacedoctor', name: 'spacedoctor', meta: {title: "Space Doctor"}, component: SpaceDoctor}
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    document.title = to?.meta?.title;
    next()
});

export default router
