<template>
  <router-view/>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      window: window,
      ruffle: null,
      ruffleLoaded: false
    }
  },
  mounted() {
    this.loadRuffle();
  },
  methods: {
    loadRuffle() {
      if (!this.ruffleLoaded) {
        this.ruffleLoaded = true;
        const script = document.createElement("script");
        script.src = "/ruffle/ruffle.js";
        document.body.appendChild(script);
      }

      if (this.ruffle === null && this.window.RufflePlayer !== undefined) {
        this.ruffle = this.window.RufflePlayer.newest();
      } else if (this.window.RufflePlayer === undefined) {
        setTimeout(_ => this.loadRuffle(), 100);
      }
    },
    async getPlayer() {
      this.loadRuffle();

      const self = this;
      return new Promise(resolve => {
        function _loop() {
          if (self.ruffle !== null) {
            resolve(self.ruffle.createPlayer())
          } else {
            setTimeout(_ => _loop(), 100)
          }
        }

        _loop();
      });
    }
  }
};
</script>

<style lang="scss">
@import "../node_modules/bulma/bulma.sass";

a, a:hover {
  color: black;
  font-weight: bold;
}

a:hover {
  text-decoration: underline;
}

html {
  height: 100%;

  body {
    height: 100%;

    ruffle-player {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
