// Forest
const forestDeciduousTrees = require("@/assets/ecosystem/forest/deciduous-trees.png");
const forestBerryBushes = require("@/assets/ecosystem/forest/berry-bushes.png");
const forestChickadees = require("@/assets/ecosystem/forest/chickadees.png");
const forestChipmunks = require("@/assets/ecosystem/forest/chipmunks.png");
const forestDeer = require("@/assets/ecosystem/forest/deer.png");
const forestFerns = require("@/assets/ecosystem/forest/ferns.png");
const forestFoxes = require("@/assets/ecosystem/forest/foxes.png");
const forestFrogs = require("@/assets/ecosystem/forest/frogs.png");
const forestGrasses = require("@/assets/ecosystem/forest/grasses.png");
const forestHawks = require("@/assets/ecosystem/forest/hawks.png");
const forestInsects = require("@/assets/ecosystem/forest/insects.png");
const forestOpossums = require("@/assets/ecosystem/forest/opossums.png");
const forestOwls = require("@/assets/ecosystem/forest/owls.png");
const forestRabbits = require("@/assets/ecosystem/forest/rabbits.png");
const forestSnakes = require("@/assets/ecosystem/forest/snakes.png");
const forestToads = require("@/assets/ecosystem/forest/toads.png");
const forestWildFlowers = require("@/assets/ecosystem/forest/wild-flowers.png");
const forestWolves = require("@/assets/ecosystem/forest/wolves.png");
const forestWoodpeckers = require("@/assets/ecosystem/forest/woodpeckers.png");

// Desert
const desertCacti = require("@/assets/ecosystem/desert/cacti.png");
const desertCarnivorousLizards = require("@/assets/ecosystem/desert/carnivorous-lizards.png");
const desertCoyotes = require("@/assets/ecosystem/desert/coyotes.png");
const desertHawks = require("@/assets/ecosystem/desert/hawks.png");
const desertHerbivorousLizards = require("@/assets/ecosystem/desert/herbivorous-lizards.png");
const desertInsects = require("@/assets/ecosystem/desert/insects.png");
const desertKangarooRats = require("@/assets/ecosystem/desert/kangaroo-rats.png");
const desertOcotillo = require("@/assets/ecosystem/desert/ocotillo.png");
const desertRabbitBrush = require("@/assets/ecosystem/desert/rabbit-brush.png");
const desertSageBrush = require("@/assets/ecosystem/desert/sage-brush.png");
const desertSnakes = require("@/assets/ecosystem/desert/snakes.png");
const desertTarantulas = require("@/assets/ecosystem/desert/tarantulas.png");
const desertWildFlowers = require("@/assets/ecosystem/desert/wild-flowers.png");

// Grassland
const grasslandBuffalo = require("@/assets/ecosystem/grassland/buffalo.png");
const grasslandCoyotes = require("@/assets/ecosystem/grassland/coyotes.png");
const grasslandGoldfinches = require("@/assets/ecosystem/grassland/goldfinches.png");
const grasslandGrasses = require("@/assets/ecosystem/grassland/grasses.png");
const grasslandGrasshoppers = require("@/assets/ecosystem/grassland/grasshoppers.png");
const grasslandGrayPartridges = require("@/assets/ecosystem/grassland/gray-partridges.png");
const grasslandHawks = require("@/assets/ecosystem/grassland/hawks.png");
const grasslandLupine = require("@/assets/ecosystem/grassland/lupine.png");
const grasslandMilkweed = require("@/assets/ecosystem/grassland/milkweed.png");
const grasslandMonarchButterflies = require("@/assets/ecosystem/grassland/monarch-butterflies.png");
const grasslandPronghorns = require("@/assets/ecosystem/grassland/pronghorns.png");
const grasslandRattleSnakes = require("@/assets/ecosystem/grassland/rattle-snakes.png");

// Antarctic
const antarcticCarnivorousFish = require("@/assets/ecosystem/antarctic/carnivorous-fish.png");
const antarcticHerbivorousFish = require("@/assets/ecosystem/antarctic/herbivorous-fish.png");
const antarcticKillerWhales = require("@/assets/ecosystem/antarctic/killer-whales.png");
const antarcticPenguins = require("@/assets/ecosystem/antarctic/penguins.png");
const antarcticPhytoplankton = require("@/assets/ecosystem/antarctic/phytoplankton.png");
const antarcticSeals = require("@/assets/ecosystem/antarctic/seals.png");
const antarcticSquid = require("@/assets/ecosystem/antarctic/squid.png");

// Lake
const lakeAlgae = require("@/assets/ecosystem/lake/algae.png");
const lakeBass = require("@/assets/ecosystem/lake/bass.png");
const lakeCrayfish = require("@/assets/ecosystem/lake/crayfish.png");
const lakeDucks = require("@/assets/ecosystem/lake/ducks.png");
const lakeFrogs = require("@/assets/ecosystem/lake/frogs.png");
const lakeHerons = require("@/assets/ecosystem/lake/herons.png");
const lakeMinnows = require("@/assets/ecosystem/lake/minnows.png");
const lakeWaterInsects = require("@/assets/ecosystem/lake/water-insects.png");

export default class Experiment {

    private _ecosystems: Ecosystem[] = [];
    private _ecosystem: Ecosystem;
    private _layers: Layers = new Layers();

    constructor() {
        this._ecosystems.push(new Ecosystem("Deciduous Forest Ecosystem",
            "The trees in the deciduous forest shed their leaves in winter. This is a forest of flowering plants with insects as pollinators. Fruit trees and berry bushes support a diverse ecology. Warm summer temperatures, with abundant moisture, promote plant growth. The ground is covered with small plants, wild flowers (example: lupine), ferns and grasses. Deer, rabbits and small rodents such as chipmunks are plant eaters. Many birds ( example: chickadees), as well as insects, eat the leaves and fruits. Woodpeckers, opossums, and frogs eat insects, while snakes, foxes, and owls eat small rodents and frogs. The top carnivores, such as cougars, wolfs and hawks, eat larger herbivores as well as many of the smaller animals that are both carnivores and herbivores.",
            "#e3f1e4", "#1dbc2f",
            [
                new Organism("Deciduous Trees", forestDeciduousTrees, "Plants", Layer.NAMES.PRODUCERS, 3456, 0.751750875437719),
                new Organism("Ferns", forestFerns, "Plants", Layer.NAMES.PRODUCERS, 541, 0.751750875437719),
                new Organism("Berry Bushes", forestBerryBushes, "Plants", Layer.NAMES.PRODUCERS, 2670, 0.751750875437719),
                new Organism("Wild Flowers", forestWildFlowers, "Plants", Layer.NAMES.PRODUCERS, 720, 0.751750875437719),
                new Organism("Grasses", forestGrasses, "Plants", Layer.NAMES.PRODUCERS, 609, 0.751750875437719),
                new Organism("Chipmunks", forestChipmunks, "Mammals", Layer.NAMES.FIRST, 1320, 0.136652774731301),
                new Organism("Deer", forestDeer, "Mammals", Layer.NAMES.FIRST, 30, 0.136652774731301),
                new Organism("Rabbits", forestRabbits, "Mammals", Layer.NAMES.FIRST, 386, 0.136652774731301),
                new Organism("Chickadees", forestChickadees, "Birds", Layer.NAMES.FIRST, 643, 0.136652774731301),
                new Organism("Insects", forestInsects, "Arthropods", Layer.NAMES.FIRST, 2180, 0.136652774731301),
                new Organism("Foxes", forestFoxes, "Mammals", Layer.NAMES.SECOND, 180, 0.0430183356840621),
                new Organism("Opossums", forestOpossums, "Mammals", Layer.NAMES.SECOND, 210, 0.0430183356840621),
                new Organism("Woodpeckers", forestWoodpeckers, "Birds", Layer.NAMES.SECOND, 310, 0.0430183356840621),
                new Organism("Owls", forestOwls, "Birds", Layer.NAMES.SECOND, 190, 0.0430183356840621),
                new Organism("Snakes", forestSnakes, "Reptiles", Layer.NAMES.SECOND, 111, 0.0430183356840621),
                new Organism("Frogs", forestFrogs, "Amphibians", Layer.NAMES.SECOND, 183, 0.0430183356840621),
                new Organism("Toads", forestToads, "Amphibians", Layer.NAMES.SECOND, 234, 0.0430183356840621),
                new Organism("Wolves", forestWolves, "Mammals", Layer.NAMES.THIRD, 20, 0.12),
                new Organism("Hawks", forestHawks, "Birds", Layer.NAMES.THIRD, 30, 0.12),
            ]));
        this._ecosystems.push(new Ecosystem("Hot Desert Ecosystem",
            "Animals and plants that live in the hot, dry desert environment require special adaptations. Desert plants have evolved special adaptations for capturing and storing water. The driest parts of a hot desert are marked with many stones and rocks. Where there is more moisture, there will be plants, such as sage brush, seasonal grasses, and small shrubs. There may be tree-sized cacti, ocotillo, and Joshua trees. Insects, lizards and rodents such as the kangaroo rat feed on the plants' seeds, flowers, and juicy bodies and leaves. Snakes and lizards eat insects and rodents. Scorpions and tarantulas also eat insects. Reptiles are cold blooded and they can survive on less food than warm-blooded animals. The warmth of the desert sun provides heat for their bodies. Small, warm-blooded animals, such as rodents, that live in the hot desert hide from the heat in burrows, and come out to feed at night. A few larger predators such as hawks may be able to survive in the desert.",
            "#e3f1e4", "#1d32bc",
            [
                new Organism("Cacti", desertCacti, "Plants", Layer.NAMES.PRODUCERS, 1204, 0.0768344218209758),
                new Organism("Ocotillo", desertOcotillo, "Plants", Layer.NAMES.PRODUCERS, 864, 0.0768344218209758),
                new Organism("Rabbit Brush", desertRabbitBrush, "Plants", Layer.NAMES.PRODUCERS, 189, 0.0768344218209758),
                new Organism("Sage Brush", desertSageBrush, "Plants", Layer.NAMES.PRODUCERS, 145, 0.0768344218209758),
                new Organism("Wild Flowers", desertWildFlowers, "Plants", Layer.NAMES.PRODUCERS, 201, 0.0768344218209758),
                new Organism("Kangaroo Rats", desertKangarooRats, "Mammals", Layer.NAMES.FIRST, 37, 0.0252707581227437),
                new Organism("Herbivorous Lizards", desertHerbivorousLizards, "Reptiles", Layer.NAMES.FIRST, 54, 0.0252707581227437),
                new Organism("Insects", desertInsects, "Arthropods", Layer.NAMES.FIRST, 740, 0.0252707581227437),
                new Organism("Snakes", desertSnakes, "Reptiles", Layer.NAMES.SECOND, 21, 0.0344827586206897),
                new Organism("Carnivorous Lizards", desertCarnivorousLizards, "Reptiles", Layer.NAMES.SECOND, 18, 0.0344827586206897),
                new Organism("Tarantulas", desertTarantulas, "Arthropods", Layer.NAMES.SECOND, 19, 0.0344827586206897),
                new Organism("Coyotes", desertCoyotes, "Mammals", Layer.NAMES.THIRD, 2, 0.038),
                new Organism("Hawks", desertHawks, "Birds", Layer.NAMES.THIRD, 3, 0.038),
            ]));
        this._ecosystems.push(new Ecosystem("Grassland Ecosystem",
            "Grasses grow in areas where there is not enough moisture to support trees, with average rainfall from 10-30 inches (25cm- 75cm) a year. In this ecosystem, there are many species of grasses. Each one is adapted to survive under particular environmental conditions. Some grasses are annuals, and survive the winters as seeds. Other species are perennials, and sprout again each year from a network of roots. About a quarter of the vegetation on Earth consists of grasses. Grasses are wind-pollinated flowering plants. The tiny flowers form at the top of the stalks, where the wind can pick up and deliver the pollen. Grasses are monocotyledons, so that when the seeds germinate, each one sends up a single leaf. Grasslands are subdivided into tall grass, medium grass, and short grass. Grass heights may be 30 inches (75cm) tall or taller, or less than a foot (30cm) in height. Shorter grass usually indicates that there is less available moisture. In North America, the grassland has a diverse fauna with bison (buffalo), antelopes such as pronghorns, and burrowing mammals (example: prairie dogs), with seed-eating birds (example: goldfinches) representing the herbivores. There are many groundnesting birds such as the partridge that eat insects such as grasshoppers. Snakes are carnivores that will eat small mammals such as the prairie dog. Predators range from grizzly bears to coyotes and foxes and include hawks and owls.",
            "#e3f1e4", "#16561e",
            [
                new Organism("Grasses", grasslandGrasses, "Plants", Layer.NAMES.PRODUCERS, 547800, 0.00364228400345289),
                new Organism("Milkweed", grasslandMilkweed, "Plants", Layer.NAMES.PRODUCERS, 980, 0.00364228400345289),
                new Organism("Lupine", grasslandLupine, "Plants", Layer.NAMES.PRODUCERS, 326, 0.00364228400345289),
                new Organism("Buffalo", grasslandBuffalo, "Mammals", Layer.NAMES.FIRST, 17, 0.118177000529942),
                new Organism("Grasshoppers", grasslandGrasshoppers, "Arthropods", Layer.NAMES.FIRST, 1740, 0.118177000529942),
                new Organism("Goldfinches", grasslandGoldfinches, "Birds", Layer.NAMES.FIRST, 42, 0.118177000529942),
                new Organism("Pronghorns", grasslandPronghorns, "Mammals", Layer.NAMES.FIRST, 24, 0.118177000529942),
                new Organism("Monarch Butterflies", grasslandMonarchButterflies, "Arthropods", Layer.NAMES.FIRST, 64, 0.118177000529942),
                new Organism("Gray Partridges", grasslandGrayPartridges, "Birds", Layer.NAMES.SECOND, 34, 0.4634146341),
                new Organism("Rattle Snakes", grasslandRattleSnakes, "Reptiles", Layer.NAMES.SECOND, 7, 0.4634146341),
                new Organism("Coyotes", grasslandCoyotes, "Mammals", Layer.NAMES.THIRD, 2, 0.4),
                new Organism("Hawks", grasslandHawks, "Birds", Layer.NAMES.THIRD, 3, 0.4),
            ]));
        this._ecosystems.push(new Ecosystem("Antarctic Ocean Shore Ecosystem",
            "The animals that live in Antarctic ocean shore ecosystems find their food in the ocean. Food production varies with the seasons. In winter, when the South Pole has the fewest hours of sunlight there is little or no sunlight for photosynthesis. In spring, ocean currents bring up nutrients from the ocean bottom, and the plankton grows rapidly. Plankton includes unicellular autotrophs that are microscopic in size. Tiny diatoms, crustaceans, and protozoans make up the plankton as well. Tiny animals, such as type of shrimp called krill and other small fishes, feed on the plankton. The carnivores include larger fishes, squid, seals, penguins, and other sea birds. Whales eat enormous numbers of krill. The top predators in this food chain are orcas, also known as killer whales. They mainly prey on penguins and seals.",
            "#e3f1e4", "#1f94e3",
            [
                new Organism("Phytoplankton", antarcticPhytoplankton, "Plants", Layer.NAMES.PRODUCERS, 5478 * 1000000, 0.00000146148229280759),
                new Organism("Squid", antarcticSquid, "Mollusks", Layer.NAMES.FIRST, 1740, 0.142441860465116),
                new Organism("Herbivorous Fish", antarcticHerbivorousFish, "Fish", Layer.NAMES.FIRST, 3764, 0.142441860465116),
                new Organism("Penguins", antarcticPenguins, "Birds", Layer.NAMES.SECOND, 42, 0.140293637846656),
                new Organism("Carnivorous Fish", antarcticCarnivorousFish, "Fish", Layer.NAMES.SECOND, 549, 0.140293637846656),
                new Organism("Seals", antarcticSeals, "Mammals", Layer.NAMES.SECOND, 22, 0.140293637846656),
                new Organism("Killer Whales", antarcticKillerWhales, "Mammals", Layer.NAMES.THIRD, 5, 1.4),
            ]));
        this._ecosystems.push(new Ecosystem("Freshwater Lake Ecosystem",
            "A freshwater ecosystem is distinguished from other aquatic ecosystems because of the water's extremely low salt content. It exists in various forms such as lakes, rivers, ponds, swamps, or wetlands such as the Florida everglades. Freshwater ecosystems are host to a wide variety of plants and animals. Lakes are divided into separate zones. The littoral zone, which is closest to the shore, is host to a wide variety of species because of its warm, shallow environment. It can include several species of algae, like diatoms, and rooted and floating aquatic plants such as water lilies and rushes. Herbivores are ducks, grazing snails, clams, insects, insect larvae and small fishes such as minnows. The animals living in the littoral zone are food for other creatures such as turtles, snakes, crustaceans (example: crayfish), and amphibians (example: frogs). The near-surface open water surrounded by the littoral zone is the limnetic zone. The limnetic zone is well lighted like the littoral zone. and is dominated by plankton. Plankton such as phytoplankton and zooplankton are small organisms that playa crucial role in the food chain. A variety of herbivorous and larger predator fish (example: bass and catfish) also occupy this zone. Plankton have short life spans. When they die, they fall into the deep water part of the ecosystem, the profundal zone. Little light penetrates all the way through the limnetic zone into the profundal zone. The decomposers live in the profundal zone.",
            "#e3f1e4", "#6bd276",
            [
                new Organism("Algae", lakeAlgae, "Plants", Layer.NAMES.PRODUCERS, 123000, 0.0609756097560976),
                new Organism("Water Insects", lakeWaterInsects, "Amphibians", Layer.NAMES.FIRST, 1740, 0.324324324324324),
                new Organism("Ducks", lakeDucks, "Birds", Layer.NAMES.FIRST, 7, 0.324324324324324),
                new Organism("Minnows", lakeMinnows, "Fish", Layer.NAMES.FIRST, 547, 0.324324324324324),
                new Organism("Bass", lakeBass, "Fish", Layer.NAMES.SECOND, 23, 1.47272727272727),
                new Organism("Frogs", lakeFrogs, "Amphibians", Layer.NAMES.SECOND, 17, 1.47272727272727),
                new Organism("Crayfish", lakeCrayfish, "Arthropods", Layer.NAMES.SECOND, 15, 1.47272727272727),
                new Organism("Herons", lakeHerons, "Birds", Layer.NAMES.THIRD, 2, 4),
            ]));

        this._ecosystem = this._ecosystems[0];
    }

    get ecosystems(): Ecosystem[] {
        return this._ecosystems;
    }

    get ecosystem(): Ecosystem {
        return this._ecosystem;
    }

    get layers(): Layer[] {
        return this._layers.layers;
    }

    set ecosystem(value: Ecosystem) {
        this._ecosystem = value;
        this.reset();
    }

    moveOrganismToLayer(layer: Layer, organism: Organism) {
        let max;
        switch (layer.name) {
            case Layer.NAMES.THIRD:
                max = 4;
                break;
            case Layer.NAMES.SECOND:
                max = 7;
                break;
            case Layer.NAMES.FIRST:
                max = 7;
                break;
            case Layer.NAMES.PRODUCERS:
            default:
                max = 7;
                break;
        }

        if (layer.organisms.length < max) {
            layer.addOrganism(organism);
        }
    }

    removeOrganism(organism: Organism) {
        this._layers.removeOrganism(organism);
    }

    reset() {
        this._layers.reset();
    }

    check() {
        this._layers.layers.forEach(layer =>
            layer.organisms.forEach(organism =>
                organism.status = organism.layer === layer.name ? STATUS.GOOD : STATUS.BAD)
        );
    }

    organismAssigned(organism: Organism): boolean {
        let found = false;
        this.layers.forEach(layer =>
            layer.organisms.forEach(next => {
                if (organism.matches(next)) found = true;
            }));
        return found;
    }

}


export class Ecosystem {

    private readonly _name: string;
    private readonly _description: string;
    private readonly _members: Organism[] = [];
    private readonly _background1: string;
    private readonly _background2: string;

    constructor(name: string, description: string, background1: string, background2: string, members: Organism[]) {
        this._name = name;
        this._description = description;
        this._members = members;
        this._background1 = background1;
        this._background2 = background2;
    }

    get background(): string {
        return `linear-gradient(${this._background1},${this._background2})`;
    }

    get name(): string {
        return this._name;
    }

    get description(): string {
        return this._description;
    }

    get members(): Organism[] {
        return this._members;
    }

    get categories(): Map<string, Organism[]> {
        const map = new Map();
        this._members.forEach(next => {
            if (!map.has(next.category)) map.set(next.category, []);
            map.get(next.category).push(next);
        })
        return map;
    }

}

export enum STATUS {
    GOOD,
    BAD,
    UNKNOWN
}

export class Organism {

    private readonly _name: string;
    private readonly _image: string;
    private readonly _category: string;
    private readonly _layer: string;
    private readonly _count: number;
    private readonly _energyRatio: number;
    private _status: STATUS = STATUS.UNKNOWN;

    constructor(name: string, image: string, category: string, layer: string, count: number, energyRatio: number) {
        this._name = name;
        this._image = image;
        this._category = category;
        this._layer = layer;
        this._count = count;
        this._energyRatio = energyRatio;
    }

    get name(): string {
        return this._name;
    }

    get image(): string {
        return this._image;
    }

    get category(): string {
        return this._category;
    }

    get layer(): string {
        return this._layer;
    }

    get count(): number {
        return this._count;
    }

    get energyRatio(): number {
        return this._energyRatio;
    }

    get status(): STATUS {
        return this._status;
    }

    set status(value: STATUS) {
        this._status = value;
    }

    serialize(): string {
        return JSON.stringify(this);
    }

    static deserialize(json: string): Organism {
        const map = JSON.parse(json);
        return new Organism(map._name, map._image, map._category, map._layer, map._count, map._energyRatio);
    }

    matches(organism: Organism): boolean {
        return organism.name === this.name &&
            organism.category === this.category;
    }

}

export class Layers {

    private _layers: Layer[] = [
        new Layer(Layer.NAMES.THIRD),
        new Layer(Layer.NAMES.SECOND),
        new Layer(Layer.NAMES.FIRST),
        new Layer(Layer.NAMES.PRODUCERS)
    ]

    get layers(): Layer[] {
        return this._layers;
    }

    reset() {
        this._layers.forEach(layer => layer.reset());
    }

    removeOrganism(organism: Organism) {
        this._layers.forEach(next => next.removeOrganism(organism));
    }

}

export class Layer {

    public static readonly NAMES = {
        PRODUCERS: "Producers",
        FIRST: "First Order Heterotrophs",
        SECOND: "Second Order Heterotrophs",
        THIRD: "Third Order Heterotrophs"
    }

    private readonly _name: String;
    private _organisms: Organism[] = [];

    constructor(name: String) {
        this._name = name;
    }

    get name(): String {
        return this._name;
    }

    get organisms(): Organism[] {
        return this._organisms;
    }

    organismCount(): number {
        let count = 0;
        this.organisms.forEach(organism => {
            if (organism.status === STATUS.GOOD) count += organism.count;
        })
        return count;
    }

    private formatNumber(number: number): string {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    formattedOrganismCount(): string {
        let text = this.formatNumber(this.organismCount());
        if (this.organismCount() > 1000000) {
            text = `${this.formatNumber(this.organismCount() / 1000 / 1000)} Million`
        }
        return text;
    }

    energyCount(): number {
        let count = 0;
        this.organisms.forEach(organism => {
            if (organism.status === STATUS.GOOD) count += organism.count * organism.energyRatio;
        })

        if (count < 1) {
            count = Math.round(count * 100) / 100;
        } else {
            count = Math.round(count)
        }
        return count;
    }

    formattedEnergyCount(): string {
        return this.formatNumber(this.energyCount());
    }

    addOrganism(organism: Organism) {
        organism.status = STATUS.UNKNOWN;
        this._organisms.push(organism);
    }

    removeOrganism(organism: Organism) {
        organism.status = STATUS.UNKNOWN;
        this._organisms.forEach(next => {
            if (next.matches(organism)) this._organisms.splice(this._organisms.indexOf(next), 1);
        })
    }

    reset() {
        this._organisms = [];
    }

}