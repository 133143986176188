export default class Timer {

    private _start: Date = new Date();
    private _end: Date = new Date();
    private _running: boolean = false;

    public startTimer() {
        if (!this.running()) {
            this._start = new Date();
            this._running = true;
        }
    }

    public stopTimer() {
        if (this.running()) {
            this._running = false;
            this._end = new Date();
        }
    }

    public toggleTimer() {
        this.running() ? this.stopTimer() : this.startTimer();
    }

    public running(): boolean {
        return this._running;
    }

    public getMinutes(): string {
        return `${this.minutesDisplay()}:${this.secondsDisplay()}`
    }

    public getSeconds(): number {
        return this._running ? this.secondsBetween(this._start, new Date()) : this.secondsBetween(this._start, this._end);
    }

    private minutesDisplay(): string {
        return this.pad(Math.floor(this.getSeconds() / 60), 2)
    }

    private secondsDisplay(): string {
        return this.pad(this.getSeconds() % 60, 2);
    }

    private secondsBetween(start: Date, end: Date): number {
        return Math.round((end.getTime() - start.getTime()) / 1000)
    }

    private pad(num: number, size: number) {
        const s = "000000000" + num;
        return s.substr(s.length - size);
    }

}
