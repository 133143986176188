<template>
  <nav-bar subtitle="Ecosystem Lab">
    <b-modal v-model="showDescription" :width="750">
      <div class="description">
        <h1 class="title is-1">{{ ecosystem.name }}</h1>
        <p>{{ ecosystem.description }}</p>
      </div>
    </b-modal>

    <div class="canvas-wrapper">
      <section class="columns is-marginless is-vcentered header">
        <div class="column">
          <h1 class="title is-1">{{ ecosystem.name }}
            <b-button @click="showDescription = true" type="is-white">
              <b-icon pack="fas" icon="info-circle"></b-icon>
            </b-button>
          </h1>

        </div>
        <div class="column is-narrow">
          <div class="buttons">
            <b-button @click="experiment.reset()" type="is-danger">Reset</b-button>
            <b-button @click="experiment.check()" type="is-info">Check</b-button>
            <b-dropdown aria-role="list">
              <button class="button is-black" slot="trigger" slot-scope="{ active }">
                <span>{{ ecosystem.name }}</span>
                <b-icon pack="fas" :icon="active ? 'arrow-up' : 'arrow-down'"></b-icon>
              </button>

              <b-dropdown-item v-for="next in ecosystems"
                               :key="next.name"
                               @click="experiment.ecosystem = next"
                               aria-role="listitem">{{ next.name }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </section>
      <section class="pyramid columns is-marginless">
        <div class="column instructions">
          <h3 class="title is-3">Description</h3>
          <div class="text-block">
            <p>An ecosystem consists of a community of living organisms interacting with each other and the environment.
              The source of energy that fuels most ecosystems is the Sun. Plants use the Sun's energy to produce food in
              a process called photosynthesis. Organisms that use energy from the Sun or energy stored in chemical
              compounds to produce their own nutrients are called autotrophs. They are also called producers because
              most other organisms depend on autotrophs for nutrients and energy. Heterotrophic organisms that cannot
              make their own food may obtain nutrients by eating other organisms. A heterotroph that feeds only on
              plants is called an herbivore. Herbivores are also called first order heterotrophs. Carnivores that feed
              on herbivores are called second order heterotrophs. Carnivores that feed on other carnivores are called
              third order heterotrophs. A food chain is a simple model of how energy and matter move through an
              ecosystem.</p>
            <p>Each level of production and consumption in a food chain is a trophic level. The autotrophs form the
              first trophic level, first order heterotrophs (herbivores) constitute the second trophic level, second
              order heterotrophs the third trophic level, and third order heterotrophs are layered on top.</p>
            <p>In the pyramid of energy, the energy moves in only one direction and decreases at each succeeding trophic
              level. The total energy transfer from one trophic level to the next is, in general, only about ten percent
              or less. This is called the energy conversion efficiency. Organisms fail to capture and eat all the food
              available at the trophic level below them. The food consumers ingest is used to metabolize and build body
              tissues. Some food is given off as waste. The energy lost at each trophic level enters the environment as
              heat.</p>
            <p>A pyramid of biomass expresses the weight of living material at each trophic level. Biomass is calculated
              by finding the average weight of each species at that trophic level and multiplying the weight by the
              estimated number of organisms in each population. In terrestrial ecosystems, biomass decreases as the
              trophic level increases. In contrast to terrestrial ecosystems, freshwater and marine ecosystems have less
              primary producer biomass than biomass present at higher trophic levels, leading to an inverted biomass
              pyramid. This is because algae and phytoplankton have a shorter lifespan, are more edible than terrestrial
              plants, and are more rapidly grazed. Their biomass does not accumulate.</p>
            <p>In this exploration, you will study and analyze five simplified model ecosystems: a deciduous forest, a
              hot desert, a freshwater lake, grassland, and an Antarctic ocean shore. Many more plant and animal species
              would be involved in a real-world ecosystem. The field notes for each model ecosystem present a profile of
              the plant and animal inventory for each ecosystem.</p>
          </div>

          <h3 class="title is-3">Procedure</h3>
          <div class="text-block">
            <ol>
              <li>Select the icon next to the animals' and plants' names from the right side and drag and drop them to
                the appropriate trophic level in the ecosystem pyramid.
              </li>
              <li>Click the Check button when all the names have been placed in the ecosystem pyramid to verify the
                accuracy of animal and plant assignments to the appropriate trophic level. For each accurate placement,
                the name icon will turn green and the numbers and energy brought by that organism will appear under the
                level title.
              </li>
              <li>When all organisms have been placed correctly and checked, the total organism number and energy
                contribution for the whole trophic level will be totaled under the level title.
              </li>
              <li>The pyramid of numbers indicates numbers of plants and animals. The pyramid of energy indicates the
                amount of energy in that trophic level.
              </li>
              <li>You can analyze this data by calculating the conversion efficiency for each trophic level for each of
                the five ecosystems. The energy conversion efficiency is calculated by dividing the energy at the higher
                trophic level by the energy at the lower level to obtain a ratio. Record the value as a decimal number.
              </li>
            </ol>
          </div>

        </div>
        <div class="column dropareas" :style="{background: background}">
          <div class="droparea"
               v-for="layer in layers">
            <div class="group-name" :class="layer.organismCount() === 0 ? 'is-solo' : null">{{ layer.name }}</div>
            <div class="group-details" v-if="layer.organismCount() > 0">
              <strong>Organisms:</strong> {{ layer.formattedOrganismCount() }} &bull; <strong>Energy:</strong>
              {{ layer.formattedEnergyCount() }}
            </div>
            <div class="organisms">
              <b-tag v-for="organism in layer.organisms"
                     :key="organism.name"
                     :type="getStatusType(organism)"
                     :closable="!isGood(organism)"
                     :class="isGood(organism) ? 'is-good' : null"
                     @close="experiment.removeOrganism(organism)">
                <div class="text"
                     v-for="word in organism.name.split(' ')"
                     :class="organism.name.split(' ').length > 1 ? 'is-2' : 'is-1'">{{ word }}
                </div>
                <div class="image" :style="{ 'background-image': `url(${organism.image})` }"></div>
              </b-tag>
            </div>
          </div>
          <div class="sun">Sun</div>
          <div class="info">Energy Loss to Decomposers</div>
          <div class="left-line">
            <canvas width="25" height="400" ref="left-line"></canvas>
          </div>
          <div class="right-line">
            <canvas width="125" height="400" ref="right-line"></canvas>
          </div>
          <div class="capture-boxes" v-if="dragging">
            <div class="capture-box"
                 v-for="layer in layers"
                 @drop='onDrop($event, layer)'
                 @dragover.prevent
                 @dragenter.prevent>
            </div>
          </div>

        </div>
        <div class="column">
          <div v-for="category in ecosystem.categories.keys()" class="eco-group">
            <div class="title is-3">{{ category }}:</div>
            <div class="tag is-info" v-for="member in ecosystem.categories.get(category)" :key="member.name"
                 v-if="!experiment.organismAssigned(member)"
                 draggable="true"
                 @dragstart='startDrag($event, member)'>{{ member.name }}
            </div>
          </div>
        </div>

      </section>
    </div>
    <div class="attribution">
      Based on <a href="http://glencoe.mheducation.com/sites/dl/free/0078802849/383926/BL_02.html" target="_blank">http://glencoe.mheducation.com/sites/dl/free/0078802849/383926/BL_02.html</a>
    </div>
  </nav-bar>
</template>

<script>
import NavBar from "@/components/NavBar";
import Experiment, {Organism, STATUS} from "@/business/EcosystemLab";

export default {
  name: "EcosystemLab",
  components: {NavBar},
  data() {
    return {
      experiment: new Experiment(),
      dragging: false,
      showDescription: false
    }
  },
  mounted() {
    this.drawLeftSize();
    this.drawRightSize();
  },
  methods: {
    startDrag(evt, organism) {
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData('organism', organism.serialize())
      this.dragging = true;
    },
    onDrop(evt, layer) {
      const organism = Organism.deserialize(evt.dataTransfer.getData('organism'));
      this.experiment.moveOrganismToLayer(layer, organism);
      this.dragging = false;
    },
    getStatusType(organism) {
      let style;
      switch (organism.status) {
        case STATUS.GOOD:
          style = "is-success";
          break;
        case STATUS.BAD:
          style = "is-danger";
          break;
        default:
          style = "is-info";
          break;
      }
      return style;
    },
    isGood(organism) {
      return organism.status === STATUS.GOOD;
    },
    drawLeftSize() {
      const canvas = this.$refs['left-line'];
      const context = canvas.getContext("2d");

      context.beginPath();
      context.strokeStyle = "#FFFFFF";
      context.lineWidth = 2;
      context.lineJoin = "round";
      context.moveTo(25, 1);

      context.lineTo(1, 1);
      context.lineTo(1, 384);
      context.lineTo(25, 384);
      context.stroke();
    },
    drawRightSize() {
      const canvas = this.$refs['right-line'];
      const context = canvas.getContext("2d");

      context.beginPath();
      context.lineWidth = 2;
      context.strokeStyle = "#FFFFFF";
      context.lineJoin = "round";
      context.moveTo(103, 384);
      context.lineTo(124, 384);
      context.lineTo(124, 320);
      context.lineTo(1, 5);
      context.lineTo(30, 5);
      context.lineTo(25, 10);
      context.moveTo(30, 5);
      context.lineTo(25, 1);
      context.stroke();
    },
  },
  computed: {
    ecosystems() {
      return this.experiment.ecosystems;
    },
    ecosystem() {
      return this.experiment.ecosystem;
    },
    layers() {
      return this.experiment.layers;
    },
    background() {
      return this.ecosystem.background;
    }
  }
}
</script>

<style lang="scss" scoped>

.description {
  border-radius: 10px;
  padding: 20px;
  background-color: #ffffff;

  .title.is-1 {
    margin-bottom: 10px;
    padding-bottom: 10px;
    font-size: 32px;
    border-bottom: 1px solid #cccccc;
  }

  p {

  }
}

.attribution {
  font-size: 14px;
  padding: 10px;
  color: #666666;

  a {
    font-weight: normal;
    color: #666666;
  }
}

.canvas-wrapper {
  flex-grow: 1;

  section {
    &.header {
      border-bottom: 1px solid #cccccc;

      .title {
        margin-bottom: 0;
        font-size: 32px;
      }

      .dropdown-item {
        padding-right: 16px;
        text-decoration: none;
      }

      .column {
        padding: 10px;
      }
    }

    .instructions {
      .title.is-3 {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;
      }

      .text-block {
        font-size: 14px;
        height: 210px;
        overflow: scroll;
        border-radius: 5px;
        padding-top: 10px;
        padding-right: 10px;
        padding-left: 10px;
        padding-bottom: 10px;
        border: 1px solid #cccccc;
        margin-right: 10px;

        ol {
          margin-top: 0;
        }
      }
    }

    &.pyramid {
      margin-top: 20px;
      padding-top: 20px;
      padding-bottom: 20px;

      .dropareas {
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: 50px;
        padding-left: 50px;
        position: relative;
        transition: all 0.5s;

        .sun, .info, .left-line, .right-line {
          position: absolute;
        }

        $top: 40px;
        $sun-size: 75px;
        $sun-middle: $sun-size/2 + $top;
        $level-height: 125px;
        $pyramid-height: $level-height*4;
        $svg-height: ($top + $pyramid-height) - $sun-middle - ($level-height/2);

        .sun {
          background-image: radial-gradient(circle, #ffff00, #f6bf35);
          border-radius: 50%;
          height: $sun-size;
          width: $sun-size;
          left: 75px;
          top: $top;
          line-height: 75px;
          text-align: center;
          font-weight: bold;
          font-size: 14px;
        }

        .info {
          top: 50px;
          right: 25px;
          background-color: #ffffff;
          width: 120px;
          padding: 8px;
          text-align: center;
          font-weight: bold;
          font-size: 14px;
        }

        .left-line {
          left: 50px;
          top: $sun-middle;
          cursor: not-allowed;
          pointer-events: none
        }

        .right-line {
          right: 50px;
          top: $top + 75px/2;
          cursor: not-allowed;
          pointer-events: none
        }

        .capture-boxes {
          .capture-box {
            position: absolute;
            height: $level-height;
            width: 500px;
            top: 20px;
            left: 50px;

            &.is-max:hover {
              background-color: rgba(255, 0, 0, 0.1);
            }

            &:nth-child(2) {
              top: 20px + $level-height;
            }

            &:nth-child(3) {
              top: 20px + 2*$level-height;
            }

            &:nth-child(4) {
              top: 20px + 3* $level-height;
            }
          }
        }

        .droparea {
          position: relative;
          background-color: rgba(0, 0, 0, 0.1);
          color: #000000;
          height: $level-height;
          margin-top: auto;
          margin-right: auto;
          margin-left: auto;
          margin-bottom: 1px;
          text-align: center;

          .organisms {
            padding-left: 40px;
            padding-right: 40px;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            .tag {
              margin-right: 5px;
              margin-bottom: 3px;
              font-size: 14px;
              font-weight: bold;
              position: relative;
              height: 35px;

              .text {
                &.is-1 {
                  line-height: 28px;
                }

                &.is-2 {
                  line-height: 14px;
                }
              }

              .image {
                top: 5px;
                position: absolute;
                filter: invert(100%);
                display: block;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                color: #000000;
                height: 25px;
                width: calc(100% - 15px);
                opacity: 0.33;
              }
            }
          }

          box-sizing: border-box;

          .group-name {
            text-align: center;
            margin: auto;
            padding-top: 5px;
            font-size: 14px;
            font-weight: bold;
            line-height: 16px;
            padding-bottom: 0;

            &.is-solo {
              padding-bottom: 5px;
            }
          }

          .group-details {
            text-align: center;
            margin: auto;
            padding-top: 0;
            font-size: 12px;
            padding-bottom: 5px;

            strong {
              color: #000000;
            }
          }

          &:nth-child(1) {
            clip-path: polygon(0 100%, 50px 0, 250px 0, 100% 100%);
            width: 300px;

            .group-name {
              width: 200px;
            }
          }

          &:nth-child(2) {
            clip-path: polygon(0 100%, 50px 0, 350px 0, 100% 100%);
            width: 400px;

            .group-name {
              width: 300px;
            }
          }

          &:nth-child(3) {
            clip-path: polygon(0 100%, 50px 0, 450px 0, 100% 100%);
            width: 500px;

            .group-name {
              width: 400px;
            }
          }

          &:nth-child(4) {
            width: 500px;

            .group-name {
              width: 500px;
            }
          }
        }
      }

      .eco-group {
        .title.is-3 {
          font-size: 14px;
          font-weight: bold;
          margin-bottom: 5px;
        }

        .tag {
          font-size: 14px;
          cursor: pointer;
          border-radius: 3px;
          margin-bottom: 10px;
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
